import React, { useContext, useEffect, useState } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/login.css';
import { Box, Button, Card, CardContent, CardHeader, Container, TextField } from '@mui/material';

const Login = () => {
    const authenticate = useContext(CableContext);
    const [cash, setCash] = useState(0);
    const [online, setOnline] = useState(0);
    const urlB64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    const handleLogin = () => {
        let username = document.getElementById("userId").value;
        let password = document.getElementById("password").value;
        let loginData = {
            "name": username,
            "password": password
        }
        if (username === "" || password === "") {
            alert("Username or Password cannot be blank.")
        } else {
            const url = `${authenticate.apiUrl}/api/auth/login/`;
            fetch(url, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginData)
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    alert("Login Failed");
                }
            }).then(data => {
                if (data !== undefined) {
                    document.cookie = `authToken=${data.authToken}`;
                    authenticate.handleAuthenticate(true);
                    // Registering Service Workers
                    if ('serviceWorker' in navigator && 'PushManager' in window) {
                        navigator.serviceWorker.register('/ServiceWorker').then(serviceWorkerRegistration => {
                            // Getting notification Permission from user
                            Notification.requestPermission().then(result => {
                                if (result === 'granted') {
                                    // Subscribing user for web push notifications
                                    serviceWorkerRegistration.pushManager.getSubscription().then(subscribed => {
                                        if (!subscribed) {
                                            serviceWorkerRegistration.pushManager.subscribe({
                                                userVisibleOnly: true,
                                                applicationServerKey: urlB64ToUint8Array("BL6EnGbIdmI_kfoXgbNIgSaKY2iASNreW1D_8eXsWRkyt7HoBK0f7PZx9hx_B_AZn8as_hTprmn1w5xghP0tSHA")
                                            }).then(subscription => {
                                                fetch(`${authenticate.apiUrl}/api/subscription/`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Auth-Token': data.authToken
                                                    },
                                                    body: JSON.stringify(subscription)
                                                });
                                            });
                                        }
                                    })
                                }
                            });
                        });
                    } else {
                        console.error('Browser does not support service workers or push messages.');
                    }
                }
            });
        }
    }
    useEffect(() => {
        if (authenticate.isLogin){
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let todayDate = new Date();
            const url = `${authenticate.apiUrl}/api/report/?recharges=${true}&from=${todayDate.getFullYear()}-${(todayDate.getMonth() + 1) < 10 ? `0${todayDate.getMonth() + 1}` : todayDate.getMonth() + 1}-${todayDate.getDate() < 10 ? `0${todayDate.getDate()}` : todayDate.getDate()}`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let totalCash = 0;
                    let totalOnline = 0;
                    data.forEach(ele => {
                        if (ele.payment === 1) {
                            totalCash += ele.total * ele.noOfMonth;
                        } else if (ele.payment === 2) {
                            totalOnline += ele.total * ele.noOfMonth;
                        }
                    });
                    setCash(Math.ceil(totalCash));
                    setOnline(Math.ceil(totalOnline));
                }
            });
        }
    }, [authenticate.isLogin])
    return (
        <>
            {!authenticate.isLogin && <form id="loginForm">
                <h2>Operator Login</h2>
                <TextField id="userId" label="User ID" variant="outlined" size='small' />
                <TextField type="password" id="password" label="Password" variant="outlined" size='small' />
                <Button variant='contained' onClick={handleLogin}>Login</Button>
            </form>}
            {authenticate.isLogin && <Container sx={{ textAlign: 'center' }}>
                <h1>Welcome To Yashdeep Cable Network</h1>
                <Box id="paymentReceivedContainer">
                    <Card raised sx={{ backgroundColor: '#000f2c', color: 'white' }}>
                        <CardHeader title="Cash" />
                        <CardContent>
                            {cash}
                        </CardContent>
                    </Card>
                    <Card raised sx={{ backgroundColor: '#000f2c', color: 'white' }}>
                        <CardHeader title="Online" />
                        <CardContent>
                            {online}
                        </CardContent>
                    </Card>
                </Box>
            </Container>}
        </>
    )
}

export default Login
