import React, { useContext, useState } from 'react';
import '../css/navbar.css';
import CableContext from '../contexts/CableContext';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Container, Drawer, IconButton, List, ListItem, Stack, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = (props) => {
    const authenticate = useContext(CableContext);
    const navigate = useNavigate();
    const [leftNavigation, setLeftNavigation] = useState(false);
    const handleLogout = () => {
        authenticate.handleAuthenticate(false);
        navigate("/");
    }
    return (
        <>
            {authenticate.isLogin && <AppBar sx={{ textAlign: 'center', backgroundColor: "#000f2c" }} position='sticky'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon onClick={() => { setLeftNavigation(true) }} />
                        <Drawer anchor='left' open={leftNavigation} onClose={() => { setLeftNavigation(false) }} PaperProps={{ sx: { backgroundColor: "#000f2c" } }}>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/recharge">Recharge</Link>
                                    </Container>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/payment">Payment</Link>
                                    </Container>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/status">Staus</Link>
                                    </Container>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/refresh">Refresh</Link>
                                    </Container>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/report">Report</Link>
                                    </Container>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Container onClick={() => { setLeftNavigation(false) }}>
                                        <Link to="/previousRecharge">Previous Recharge</Link>
                                    </Container>
                                </ListItem>
                            </List>
                        </Drawer>
                    </IconButton>
                    <Container sx={{ padding: "0.5rem" }}>
                        <img src={props.logo} alt="Logo" className='publicLogo' />
                    </Container>
                    <Container id="totalBlock">
                        {(authenticate.autoRenew === true && authenticate.previousRecharge.length > 0) ? Math.ceil(authenticate.previousRecharge[0].total) * authenticate.noOfMonths : Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths}
                    </Container>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="logout"
                        sx={{ ml: 2 }}
                    >
                        <LogoutIcon onClick={handleLogout} />
                    </IconButton>
                </Toolbar>
            </AppBar>}
            {!authenticate.isLogin && <AppBar sx={{ textAlign: 'center', backgroundColor: "#000f2c" }} position='sticky'>
                    <Toolbar>
                        <Container sx={{ padding: "1rem" }}>
                            <img src={props.logo} alt="Logo" className='publicLogo' />
                        </Container>
                        <Container id="totalBlock">
                            {Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths}
                        </Container>
                    </Toolbar>
                    <Stack direction="row" spacing={2} justifyContent="space-evenly" justifyItems="center" className='publicNavigation'>
                        <Link to="/login">
                            Operator Login
                        </Link>
                        <Link to="/subscriber">
                            Recharge
                        </Link>
                    </Stack>
                </AppBar>}
        </>
    )
}

export default Navbar
