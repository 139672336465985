import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CableContext from '../contexts/CableContext';
import Subscriber from './Subscriber';
import { Button, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';


const PreviousRecharges = () => {
    const authenticate = useContext(CableContext);
    const [selectedSubscriber, setSelectedSubscriber] = useState(false);
    const [previousRecharges, setPreviousRecharges] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/login");
        }
    }, [])
    const handlePreviousRecharge = () => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        if (selectedSubscriber !== false){
            const previousRechargeUrl = `${authenticate.apiUrl}/api/recharge/?stbNumber=${selectedSubscriber}&all=True`;
            const url = `${authenticate.apiUrl}/api/recharge/`;
            fetch(previousRechargeUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    setPreviousRecharges(data);
                }
            });
        }else{
            alert("Please select subscriber to get recharge.");
        }
    }
    return (
    <Container sx={{pt:"2rem", textAlign:"center"}}>
        <Subscriber setSelectedSubscriber={setSelectedSubscriber} />
        <Button variant='contained' onClick={handlePreviousRecharge} sx={{mt:"1rem", mb:"1rem"}}>Get Recharge</Button>
        {previousRecharges.length > 0 ? <TableContainer component={Paper} sx={{ mt: "0.5rem" }}>
                    <Table stickyHeader sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Channel Name</TableCell>
                                <TableCell align="right">From</TableCell>
                                <TableCell align="right">To</TableCell>
                                <TableCell align="right">Rate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {previousRecharges.map(ele => {
                                return (
                                    <TableRow
                                        key={`${ele.channelId}-${ele.fromDate}-${ele.toDate}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {ele.channelName}
                                        </TableCell>
                                        <TableCell align="right">{ele.fromDate}</TableCell>
                                        <TableCell align="right">{ele.toDate}</TableCell>
                                        <TableCell align="right">{Math.ceil(ele.rate)}</TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer> : <div>No Previous Recharge Data Available.</div>}
    </Container>
    )
}

export default PreviousRecharges
