import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CableContext from '../contexts/CableContext';
import Subscriber from './Subscriber';
import '../css/refresh.css';
import { Button, Container } from '@mui/material';

const Refresh = () => {
    const authenticate = useContext(CableContext);
    const [selectedSubscriber, setSelectedSubscriber] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/login");
        }
    }, [])
    const handleRefresh = () => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        if (selectedSubscriber !== false){
            let formData = {
                "stbNo": selectedSubscriber,
                "refresh": true
            }
            const url = `${authenticate.apiUrl}/api/recharge/`;
            fetch(url, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(formData)
            }).then(response => {
                if (response.status === 200) {
                    alert("Recharge Refreshed Successfully");
                } else {
                    alert("Some error occued");
                }
            });
        }else{
            alert("Please select subscriber to refresh.");
        }
    }
    return (
        <Container sx={{pt:"2rem", textAlign:"center"}}>
            <Subscriber setSelectedSubscriber={setSelectedSubscriber} />
            <Button variant='contained' onClick={handleRefresh} sx={{mt:"1rem", mb:"1rem"}}>Refresh</Button>
        </Container>
    )
}

export default Refresh
