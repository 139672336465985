import React, { useContext, useEffect, useState } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/home.css';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  const authenticate = useContext(CableContext);
  const [packageData, setPackageData] = useState([]);
  useEffect(() => {
    const url = `${authenticate.apiUrl}/api/package/`;
    fetch(url, {
      method: "GET",
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      }
    }).then(data => {
      setPackageData(data);
    });
  }, []);
  return (
    <>
      <div id="packageSection">
        <div>
          Channels in packs
        </div>
        {packageData.map((element) => {
          return <div className="packageGrid" key={element["name"]}>
            <div>{element["name"].toUpperCase()}</div>
            {Object.keys(element["channels"]).map((ele, ind) => {
              return <React.Fragment key={ind}>
                <div className={ind === Object.keys(element["channels"]).length - 1 ? "lastInnerDiv borderRadiusLeft wrapByWord" : "innerDiv borderRadiusLeft wrapByWord"}>{ele.toUpperCase()}</div>
                <div className={ind === Object.keys(element["channels"]).length - 1 ? "lastInnerDiv borderRadiusRight borderRight" : "innerDiv borderRadiusRight borderRight"}>
                  {element["channels"][ele].map((e, index) => {
                    return <React.Fragment key={`${e}/${element["name"]}`}>{index === element["channels"][ele].length - 1 ? e : `${e} ,`}</React.Fragment>
                  })}
                </div>
              </React.Fragment>
            })}
          </div>
        })}
      </div>
      <footer id="PageFooter">
        <Container sx={{textAlign:"center"}}>
          {/* <a href='https://merchant.razorpay.com/policy/MFEXdVuY7wKkTR/terms' target='_blank' rel="noreferrer">Private policy, terms and condition, contact us and more</a> */}
          <Link to='/support'>
            Contact Us / Customer Support
          </Link>
        </Container>
      </footer>
    </>
  )
}

export default Home
