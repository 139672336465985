import React, { useContext, useEffect, useState } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/subscriber.css';
import { Autocomplete, Box, TextField } from '@mui/material';

const Subscriber = (props) => {
    const authenticate = useContext(CableContext);
    const handleSubscriberClicked = (stbNo) => {
        if (stbNo != null){
            props.setSelectedSubscriber(stbNo);
        }else{
            props.setSelectedSubscriber(false);
        }
    }
    useEffect(() => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        const subscriberUrl = `${authenticate.apiUrl}/api/subscriber/`;
        fetch(subscriberUrl, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
        }).then(data => {
            if (data !== undefined) {
                let parsedData = JSON.parse(data);
                authenticate.setSubscribers(parsedData);
            }
        });
    }, [])
    return (
        <>
            <Autocomplete
                id="name"
                sx={{ width: "100%" }}
                size="small"
                options={authenticate.subscribers}
                autoHighlight
                getOptionLabel={(option) => `${option.fields.name}|${option.fields.stbNo}`}
                renderOption={(props, option) => (
                    <Box {...props}>
                        {option.fields.stbNo}<br/>{option.fields.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose a Name"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
                onChange={(e,val) => {
                    if (val){
                        handleSubscriberClicked(val.fields.stbNo);
                    }else{
                        props.setSelectedSubscriber(false);
                    }
                }}
            />
        </>
    )
}

export default Subscriber
