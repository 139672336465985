import { Container } from '@mui/material';
import React from 'react';

const PageNotFound = () => {
    return (
        <Container sx={{pt:"1.5rem", textAlign:"center"}}>
            <h2>Not Found</h2>
        </Container>
    )
}

export default PageNotFound
