import React, { useContext, useEffect, useRef, useState } from 'react';
import CableContext from '../contexts/CableContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import "../css/report.css";

const Report = () => {
    const authenticate = useContext(CableContext);
    const navigate = useNavigate();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [subscribersData, setSubscribersData] = useState({});
    const [rechargeData, setRechargeData] = useState([]);
    const [linesData, setLinesData] = useState([]);
    const [line, setLine] = useState("all");
    const [total, setTotal] = useState({});
    useEffect(() => {
        if (authenticate.isLogin) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const subscriberUrl = `${authenticate.apiUrl}/api/subscriber/`;
            fetch(subscriberUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let parsedData = JSON.parse(data);
                    let subscriberDict = {};
                    let lines = [];
                    parsedData.forEach(ele => {
                        if (!lines.includes(ele.fields.line)) {
                            lines.push(ele.fields.line);
                        }
                        setLinesData(lines);
                        subscriberDict[ele.pk] = {
                            "name": ele.fields.name,
                            "stbNo": ele.fields.stbNo,
                            "line": ele.fields.line
                        };
                    });
                    setSubscribersData(subscriberDict);
                }
            });
        } else {
            navigate("/login");
        }
    }, []);
    const handleGetReport = () => {
        if (fromDate && toDate) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const url = `${authenticate.apiUrl}/api/report/?recharges=${true}&from=${fromDate["$y"]}-${(fromDate["$M"] + 1) < 10 ? `0${fromDate["$M"] + 1}` : fromDate["$M"] + 1}-${fromDate["$D"] < 10 ? `0${fromDate["$D"]}` : fromDate["$D"]}&to=${toDate["$y"]}-${(toDate["$M"] + 1) < 10 ? `0${toDate["$M"] + 1}` : toDate["$M"] + 1}-${toDate["$D"] < 10 ? `0${toDate["$D"]}` : toDate["$D"]}`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    setRechargeData(data);
                }
            });
        } else if (fromDate) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const url = `${authenticate.apiUrl}/api/report/?recharges=${true}&from=${fromDate["$y"]}-${(fromDate["$M"] + 1) < 10 ? `0${fromDate["$M"] + 1}` : fromDate["$M"] + 1}-${fromDate["$D"] < 10 ? `0${fromDate["$D"]}` : fromDate["$D"]}`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    setRechargeData(data);
                }
            });
        } else {
            alert("Please Select Date");
        }
    }
    useEffect(() => {
        if (subscribersData) {
            let total = {
                "all": {
                    "cash": 0,
                    "online": 0,
                    "pending": 0
                }
            };
            rechargeData.forEach(ele => {
                if (!Object.keys(total).includes(subscribersData[ele.subscriber].line)) {
                    total[subscribersData[ele.subscriber].line] = {
                        "cash": 0,
                        "online": 0,
                        "pending": 0
                    };
                }
                if (ele.payment === 1) {
                    total[subscribersData[ele.subscriber].line].cash += ele.total * ele.noOfMonth;
                    total["all"].cash += ele.total * ele.noOfMonth;
                } else if (ele.payment === 2) {
                    total[subscribersData[ele.subscriber].line].online += ele.total * ele.noOfMonth;
                    total["all"].online += ele.total * ele.noOfMonth;
                } else {
                    total[subscribersData[ele.subscriber].line].pending += ele.total * ele.noOfMonth;
                    total["all"].pending += ele.total * ele.noOfMonth;
                }
            })
            setTotal(total);
        }
    }, [rechargeData, subscribersData]);
    return (
        <>
            <Container id="datePickers">
                <DatePicker label='From' value={fromDate} onChange={(newValue) => { setFromDate(newValue) }} />
                <DatePicker label='To' value={toDate} onChange={(newValue) => { setToDate(newValue) }} />
                <Button variant="contained" id='ReportBtnContainer' onClick={handleGetReport}>Get Report</Button>
            </Container>
            {rechargeData.length > 0 ? <Container>
                <FormControl sx={{ width: "100%", mt: "1rem" }}>
                    <InputLabel id="lineLabel">Line</InputLabel>
                    <Select
                        labelId="lineLabel"
                        id="line"
                        value={line}
                        label="Line"
                        size='small'
                        onChange={(newValue) => { setLine(newValue.target.value) }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        {linesData.map(ele => {
                            return <MenuItem key={ele} value={ele}>{ele}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Box id="totalBlocks">
                    <div>
                        <div>Cash</div>
                        <div>{Math.ceil(total[line].cash)}</div>
                    </div>
                    <div>
                        <div>Online</div>
                        <div>{Math.ceil(total[line].online)}</div>
                    </div>
                    <div>
                        <div>Pending</div>
                        <div>{Math.ceil(total[line].pending)}</div>
                    </div>
                    <div>
                        <div>Total</div>
                        <div>{Math.ceil(total[line].cash) + Math.ceil(total[line].online)}</div>
                    </div>
                </Box>
                <TableContainer component={Paper} sx={{ mt: "0.5rem" }}>
                    <Table stickyHeader sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">StbNo</TableCell>
                                {line === "all" && <TableCell align="right">Line</TableCell>}
                                <TableCell align="right">Channel Name</TableCell>
                                <TableCell align="right">No. Of Months</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell align="right">Payment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rechargeData.map(ele => {
                                return (
                                    line !== "all" ? subscribersData[ele.subscriber].line === line &&
                                        <TableRow
                                            key={ele.pk}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {subscribersData[ele.subscriber].name}
                                            </TableCell>
                                            <TableCell align="right">{subscribersData[ele.subscriber].stbNo}</TableCell>
                                            <TableCell align="right">{ele.channel}</TableCell>
                                            <TableCell align="right">{ele.noOfMonth}</TableCell>
                                            <TableCell align="right">{Math.ceil(ele.total)}</TableCell>
                                            <TableCell align="right">{ele.payment === 1 ? "Cash" : ele.payment === 2 ? "Online" : "Pending"}</TableCell>
                                        </TableRow> :
                                        <TableRow
                                            key={ele.pk}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {subscribersData[ele.subscriber].name}
                                            </TableCell>
                                            <TableCell align="right">{subscribersData[ele.subscriber].stbNo}</TableCell>
                                            <TableCell align="right">{subscribersData[ele.subscriber].line}</TableCell>
                                            <TableCell align="right">{ele.channel}</TableCell>
                                            <TableCell align="right">{ele.noOfMonth}</TableCell>
                                            <TableCell align="right">{Math.ceil(ele.total)}</TableCell>
                                            <TableCell align="right">{ele.payment === 1 ? "Cash" : ele.payment === 2 ? "Online" : "Not Done"}</TableCell>
                                        </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container> : <Container sx={{ textAlign: "center", mt: "1rem" }}>No Data Available</Container>}
        </>
    )
}

export default Report
