import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/payment.css';
import CableContext from '../contexts/CableContext';
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const Payment = () => {
    const authenticate = useContext(CableContext);
    const [pendingPaymentData, setPaymentData] = useState([]);
    const [editSingle, setEditSingle] = useState(false);
    const [editMultiple, setEditMultiple] = useState([]);
    const [payment, setPayment] = useState("select");
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticate.isLogin) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const url = `${authenticate.apiUrl}/api/recharge/?pendingPayment=true`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    setPaymentData(data);
                }
            });
            const paymentMethodUrl = `${authenticate.apiUrl}/api/payment/method/`;
            fetch(paymentMethodUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let parsedData = JSON.parse(data);
                    authenticate.setPaymentMethods(parsedData);
                }
            });
        } else {
            navigate("/login");
        }
    }, []);
    const handleEditSingle = (cstomerId, toDate) => {
        document.getElementById("messageBoxChanged").style.display = "grid";
        document.getElementById("overley-back").style.display = "block";
        setEditSingle({"cstomerId" : cstomerId, "toDate" : toDate});
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
    const handleSelectedRecharges = (cstomerId, toDate, e) => {
        if (e.target.checked) {
            let localEditMultiple = editMultiple;
            localEditMultiple.push({"cstomerId" : cstomerId, "toDate" : toDate});
            setEditMultiple(localEditMultiple);
        } else {
            let localEditMultiple = [];
            editMultiple.forEach(ele => {
                if (!(cstomerId === ele.cstomerId && toDate === ele.toDate)) {
                    localEditMultiple.push(ele);
                }
            });
            setEditMultiple(localEditMultiple);
        }
    }
    const handleEditMultiple = () => {
        document.getElementById("messageBoxChanged").style.display = "grid";
        document.getElementById("overley-back").style.display = "block";
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
    const handleCancelEdit = () => {
        document.getElementById("messageBoxChanged").style.display = "none";
        document.getElementById("overley-back").style.display = "none";
        setEditSingle(false);
    }
    const handlePaymentChanged = (e) => {
        setPayment(e.target.value);
    }
    const handleSaveEdited = () => {
        if (editSingle !== false) {
            if (payment !== "select") {
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                let formData = {
                    "customerId": editSingle.cstomerId,
                    "toDate": editSingle.toDate,
                    "payType": payment
                }
                const url = `${authenticate.apiUrl}/api/recharge/`;
                fetch(url, {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(formData)
                }).then(response => {
                    if (response.status === 200) {
                        document.getElementById("messageBoxChanged").style.display = "none";
                        document.getElementById("overley-back").style.display = "none";
                        setEditSingle(false);
                        const url = `${authenticate.apiUrl}/api/recharge/?pendingPayment=true`;
                        fetch(url, {
                            method: "GET",
                            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            }
                        }).then(data => {
                            if (data !== undefined) {
                                setPaymentData(data);
                            }
                        });
                    } else {
                        alert("Some error occued");
                    }
                });
            }
        } else {
            if (payment !== "select") {
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                editMultiple.forEach(element => {
                    let formData = {
                        "customerId": element.cstomerId,
                        "toDate": element.toDate,
                        "payType": payment
                    }
                    const url = `${authenticate.apiUrl}/api/recharge/`;
                    fetch(url, {
                        method: "PUT",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                        body: JSON.stringify(formData)
                    }).then(response => {
                        if (response.status === 200) {
                            document.getElementById("messageBoxChanged").style.display = "none";
                            document.getElementById("overley-back").style.display = "none";
                            setEditMultiple([]);
                            let checkBoxes = document.querySelectorAll(".selectedCheckboxes");
                            checkBoxes.forEach(ele => {
                                ele.checked = false;
                            });
                            const url = `${authenticate.apiUrl}/api/recharge/?pendingPayment=true`;
                            fetch(url, {
                                method: "GET",
                                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                }
                            }).then(data => {
                                if (data !== undefined) {
                                    setPaymentData(data);
                                }
                            });
                        } else {
                            alert("Some error occued");
                        }
                    });
                });
            }
        }
    }
    return (
        <>
            <div id="pendingPaymentSection">
                {pendingPaymentData.length === 0 ? <Box sx={{textAlign:"center"}}>No Pending Payments Yet</Box> : <>
                    <div>
                        <div></div>
                        <div>Name</div>
                        <div>Edit</div>
                    </div>
                    {Object.keys(pendingPaymentData).map(element => {
                        return <React.Fragment key={`${pendingPaymentData[element].stbNo}-${pendingPaymentData[element].cstomerId}`}>
                            {Object.keys(pendingPaymentData[element]["recharges"]).map(ele => {
                                return <div key={`${pendingPaymentData[element].stbNo}-${pendingPaymentData[element].cstomerId}-${ele}`}>
                                    <div>
                                        <Checkbox className="selectedCheckboxes" onClick={(e) => handleSelectedRecharges(pendingPaymentData[element]["cstomerId"], ele, e)} />
                                    </div>
                                    <div>{element}</div>
                                    <div>
                                        <Button variant="contained" className="editSingle" onClick={() => { handleEditSingle(pendingPaymentData[element]["cstomerId"], ele) }}>
                                            <EditIcon />
                                        </Button>
                                    </div>
                                </div>
                            })}
                        </React.Fragment>
                    })}
                    <div>
                        <Button variant="contained" id="EditBtn" onClick={handleEditMultiple} >Edit Selected</Button>
                    </div></>}
            </div>
            <div id='messageBoxChanged'>
                <div>
                    <label htmlFor="payment">Payment</label>
                </div>
                <div>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="paymentLabel">Payment</InputLabel>
                        <Select
                            labelId="paymentLabel"
                            id="payment"
                            value={payment}
                            label="Payment"
                            size='small'
                            onChange={handlePaymentChanged}
                        >
                            <MenuItem value="select">Select</MenuItem>
                            {authenticate.paymentMethods.map(ele => {
                                return <MenuItem key={ele.pk} value={ele.fields.method}>{ele.fields.method}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <Button variant="contained" id="saveBtn" onClick={handleSaveEdited} sx={{width:"100%"}}>Save</Button>
                </div>
                <div>
                    <Button variant="contained" id="exitBtn" onClick={handleCancelEdit} sx={{width:"100%"}}>Cancel</Button>
                </div>
            </div>
            <div id="overley-back"></div>
        </>
    )
}

export default Payment
