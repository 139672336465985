import { Container } from '@mui/material';
import React from 'react';

const Support = () => {
  return (
    <Container sx={{mt: '1rem'}}>
        <h3>Support Email - devidasdarekar340@gmail.com</h3>
        <h3>Support Phone No - +91 9421002923</h3>
        <h3>Operational Address - Ashram Road, Uruli Kanchan Pune MAHARASHTRA 412202</h3>
    </Container>
  )
}

export default Support
