import React, { useContext, useEffect } from 'react';
import '../css/selectedSubscriber.css';
import { Button, Container, TextField } from '@mui/material';
import CableContext from '../contexts/CableContext';
import { useNavigate } from 'react-router-dom';

const SelectedSubscriber = () => {
    const authenticate = useContext(CableContext);
    const navigate = useNavigate();
    const handleSubscriberSearch = () => {
        let stbNo = document.getElementById("stbNo").value.toUpperCase().trim();
        const url = `${authenticate.apiUrl}/api/selectedSubscriber/${stbNo}/`;
        fetch(url, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                authenticate.setSelectedSubscriberData(false);
                alert("Please enter valid stb number to recharge");
            }
        }).then(data => {
            if (data !== undefined) {
                authenticate.setSelectedSubscriberData(data);
            }
        });
    }
    const handleSelectAndAddChannels = () => {
        navigate("/selectAndAddChannels");
    }
    const handleRepeatPreviousRecharge = () => {
        authenticate.setAutoRenew(true);
        navigate("/checkout");
    }
    useEffect(() => {
        authenticate.setSelectedSubscriberData(false);
        authenticate.setSelectedChannels([]);
        authenticate.setActiveBasicBouquet("");
        authenticate.setAutoRenew(false);
        authenticate.setNoOfMonths(1);
        authenticate.setPreviousRecharge([]);
        authenticate.setRechargeTotal(0);
    }, [])
    return (
        <Container id='selectedSubscriberForm'>
            <TextField id="stbNo" label="STB Number" variant="outlined" size='small' />
            <Button variant='contained' onClick={handleSubscriberSearch}>Search</Button>
            {authenticate.selectedSubscriberData !== false ? <>
                <h2>STB Found</h2>
                {authenticate.selectedSubscriberData.pending > 0 && <h3>You have previous pending payment of {Math.ceil(authenticate.selectedSubscriberData.pending)} rs it will be applied while performing recharge.</h3>}
                <p>Name - {authenticate.selectedSubscriberData.name}</p>
                <div className='serachedResults'>
                    {/* {new Date() < new Date(authenticate.selectedSubscriberData.previous_recharge.toDate) ? <Button variant='contained' onClick={handleSelectAndAddChannels}>Add Channels</Button> : <Button variant='contained' onClick={handleSelectAndAddChannels}>Select Channels</Button>} */}
                    {authenticate.selectedSubscriberData.previous_recharge.total !== 0 && <Button variant='contained' onClick={handleRepeatPreviousRecharge}>Repeat Previous Recharge</Button>}
                </div>
            </> : <div></div>}
        </Container>
    )
}

export default SelectedSubscriber
