import React, { useContext, useState } from 'react';
import CableContext from '../contexts/CableContext';
import { CardActions, CardContent, CardHeader, Card, Radio, Checkbox } from '@mui/material';

const Channel = (props) => {
    const authenticate = useContext(CableContext);
    const handleChannels = (element, e) => {
        let selectedChannels = authenticate.selectedChannels;
        if (e.target.checked){
            document.getElementById("totalBlock").style.display = "block";
            selectedChannels.push(element);
            if (element.category === "basic bouquets"){
                if (authenticate.activeBasicBouquet === ""){
                    authenticate.setRechargeTotal(parseFloat((element.subscriberRate + authenticate.rechargeTotal).toFixed(2)));
                    authenticate.setActiveBasicBouquet(element);
                }else{
                    let localTotal = parseFloat((element.subscriberRate + authenticate.rechargeTotal).toFixed(2));
                    let newSelectedChannels = [];
                    selectedChannels.forEach(ele => {
                        if (ele.channelId !== authenticate.activeBasicBouquet.channelId){
                            newSelectedChannels.push(ele);
                        }
                    });
                    selectedChannels = newSelectedChannels;
                    authenticate.setRechargeTotal(Math.abs(parseFloat((authenticate.activeBasicBouquet.subscriberRate - localTotal).toFixed(2))));
                    authenticate.setActiveBasicBouquet(element);
                }
            }else{
                authenticate.setRechargeTotal(parseFloat((((element.subscriberRate * 0.18) + element.subscriberRate) + authenticate.rechargeTotal).toFixed(2)));
            }
            authenticate.setSelectedChannels(selectedChannels);
        }else{
            let selectedLocalChannels = [];
            selectedChannels.forEach(ele => {
                if (ele.channelId !== element.channelId){
                    selectedLocalChannels.push(ele);
                }
            });
            selectedChannels = selectedLocalChannels;
            if (element.category === "basic bouquets"){
                authenticate.setRechargeTotal(parseFloat((element.subscriberRate - authenticate.rechargeTotal).toFixed(2)));
            }else{
                authenticate.setRechargeTotal(Math.abs(parseFloat((((element.subscriberRate * 0.18) + element.subscriberRate) - authenticate.rechargeTotal).toFixed(2))));
            }
            authenticate.setSelectedChannels(selectedChannels);
        }
    }
    return (
        <>
            {props.categoryDict[props.category].map(element => {
                return <Card key={element.channelId} raised sx={{backgroundColor:'#000f2c', color:'white'}}>
                    <CardHeader title={element.channelName} />
                    <CardContent>
                        Rs {element.subscriberRate}
                    </CardContent>
                    <CardActions>
                        {element.category === "basic bouquets" ? <Radio checked={authenticate.selectedChannels.includes(element)} name='basic-bouquets' onChange={(e) => {handleChannels(element, e)}} value={element.channelName} sx={{color:'white'}}/> : <Checkbox checked={authenticate.selectedChannels.includes(element)} onChange={(e) => {handleChannels(element, e)}} sx={{color:'white'}}/>}
                    </CardActions>
                </Card>
            })}
        </>
    )
}

export default Channel
