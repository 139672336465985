import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CableContext from '../contexts/CableContext';
import '../css/status.css';
import { Box, Button, Checkbox } from '@mui/material';

const Status = () => {
    const authenticate = useContext(CableContext);
    const [failedRecharges, setFailedRecharges] = useState([]);
    const [retryMultiple, setRetryMultiple] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticate.isLogin) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const url = `${authenticate.apiUrl}/api/recharge/?failedRecharge=true`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    setFailedRecharges(data);
                }
            });
        } else {
            navigate("/login");
        }
    }, []);
    const handleRetrySingle = (cstomerId) => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        let formData = {
            "customerId": cstomerId,
            "retry": true
        }
        const url = `${authenticate.apiUrl}/api/recharge/`;
        fetch(url, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.status === 200) {
                const failedUrl = `${authenticate.apiUrl}/api/recharge/?failedRecharge=true`;
                fetch(failedUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                }).then(data => {
                    if (data !== undefined) {
                        setFailedRecharges(data);
                    }
                });
            } else {
                alert("Some error occued");
            }
        });
    }
    const handleSelectedRecharges = (cstomerId, e) => {
        if (e.target.checked) {
            let localRetryMultiple = retryMultiple;
            localRetryMultiple.push({"cstomerId" : cstomerId});
            setRetryMultiple(localRetryMultiple);
        } else {
            let localRetryMultiple = [];
            retryMultiple.forEach(ele => {
                if (!(cstomerId === ele.cstomerId)) {
                    localRetryMultiple.push(ele);
                }
            });
            setRetryMultiple(localRetryMultiple);
        }
    }
    const handleRetryMultiple = () => {
        retryMultiple.forEach(element => {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let formData = {
                "customerId": element.cstomerId,
                "retry": true
            }
            const url = `${authenticate.apiUrl}/api/recharge/`;
            fetch(url, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(formData)
            }).then(response => {
                if (response.status === 200) {
                    const failedUrl = `${authenticate.apiUrl}/api/recharge/?failedRecharge=true`;
                    fetch(failedUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                    }).then(data => {
                        if (data !== undefined) {
                            setFailedRecharges(data);
                        }
                    });
                } else {
                    alert("Some error occued");
                }
            });
        });
    }
    return (
        <section id="failedRechargeSection">
            {Object.keys(failedRecharges).length === 0 ? <Box sx={{textAlign:"center"}}>No Failed Recharges Yet</Box> : <>
                <div>
                    <div></div>
                    <div>Name</div>
                    <div>Retry</div>
                </div>
                {Object.keys(failedRecharges).map(element => {
                    return <div key={`${failedRecharges[element].stbNo}-${failedRecharges[element].cstomerId}`}>
                        <div>
                            <Checkbox className="selectedCheckboxes" onClick={(e) => handleSelectedRecharges(failedRecharges[element].cstomerId, e)} />
                        </div>
                        <div>{element}</div>
                        <div>
                            <Button variant='contained' className="retrySingle" onClick={() => { handleRetrySingle(failedRecharges[element].cstomerId) }} >Retry</Button>
                        </div>
                        {/* {Object.keys(failedRecharges[element]["recharges"]).map(ele => {
                            return <React.Fragment key={`${failedRecharges[element].stbNo}-${failedRecharges[element].cstomerId}-${ele}`}>
                                // Failed channels code here
                            </React.Fragment>
                        })} */}
                    </div>
                })}
                <div>
                    <Button variant='contained' id="RetryBtn" onClick={handleRetryMultiple} >Retry Selected</Button>
                </div></>}

        </section>
    )
}

export default Status
