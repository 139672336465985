import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const CanceledPayment = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);
    return (
        <div>
            You Cancelled Payment
            <div>Redirecting you in 5 seconds</div>
        </div>
    )
}

export default CanceledPayment
