import React, { useEffect, useContext, useState } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/recharge.css';
import { useNavigate } from 'react-router-dom';
import Subscriber from './Subscriber';
import Channel from './Channel';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const Recharge = () => {
    const authenticate = useContext(CableContext);
    const [selectedSubscriber, setSelectedSubscriber] = useState(false);
    const [categoryDict, setCategoryDict] = useState({});
    const [payment, setPayment] = useState("select");
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticate.isLogin) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const url = `${authenticate.apiUrl}/api/line/balance/`;
            fetch(url, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    data.forEach((element) => {
                        if (element.balance <= 500) {
                            alert(`Low balance ${element.name}`);
                        }
                    });
                }
            });
            const categiryUrl = `${authenticate.apiUrl}/api/category/`;
            fetch(categiryUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let parsedData = JSON.parse(data);
                    let categoryDict = {};
                    parsedData.forEach(ele => {
                        categoryDict[ele.pk] = [];
                    });
                    categoryDict["other"] = [];
                    authenticate.setCategories(parsedData);
                    const channelUrl = `${authenticate.apiUrl}/api/channels/`;
                    fetch(channelUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(responsech => {
                        if (responsech.status === 200) {
                            return responsech.json();
                        }
                    }).then(datach => {
                        if (datach !== undefined) {
                            datach.forEach(ele => {
                                if (ele.category !== null) {
                                    categoryDict[ele.category].push(ele);
                                } else {
                                    categoryDict["other"].push(ele);
                                }
                            });
                            setCategoryDict(categoryDict);
                            authenticate.setChannels(datach);

                        }
                    });
                }
            });
            const paymentMethodUrl = `${authenticate.apiUrl}/api/payment/method/`;
            fetch(paymentMethodUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let parsedData = JSON.parse(data);
                    authenticate.setPaymentMethods(parsedData);
                }
            });
        } else {
            navigate("/login");
        }
    }, [])
    useEffect(() => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        if (selectedSubscriber !== false) {
            const previousRechargeUrl = `${authenticate.apiUrl}/api/recharge/?stbNumber=${selectedSubscriber}`;
            fetch(previousRechargeUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    authenticate.setPreviousRecharge(data);
                }
            });
        }
    }, [selectedSubscriber])
    const handleMonthValue = (e) => {
        authenticate.setNoOfMonths(e.target.value);
    }
    const handleAutoRenewChanged = (e) => {
        if (e.target.checked) {
            authenticate.setAutoRenew(true);
            document.getElementById("totalBlock").style.display = "block";
            document.getElementById("categories").style.display = "none";
        } else {
            authenticate.setAutoRenew(false);
            document.getElementById("categories").style.display = "block";
        }
    }
    const handlePaymentChanged = (e) => {
        setPayment(e.target.value);
    }
    const handleRecharge = () => {
        if (selectedSubscriber === false) {
            alert("Please Select Subscriber");
        }
        else if (authenticate.noOfMonths <= 0) {
            alert("No Of month cannot be zero or less");
        } else if (authenticate.autoRenew === false && authenticate.selectedChannels.length === 0) {
            alert("Please Select Channels To Recharge");
        } else {
            let userInput = null;
            if (authenticate.previousRecharge.length > 0){
                let todayDate = new Date();
                let toDate = new Date(authenticate.previousRecharge[0].toDate);
                if (todayDate < toDate){
                    userInput = window.confirm("This recharge is active still you want to do it again.");
                }
            }
            if (userInput === null || userInput === true){
                let formData = {
                    "stbNumber": selectedSubscriber,
                    "payment": payment === "select" ? null : payment,
                    "autoRenew": authenticate.autoRenew,
                    "totalRs": (authenticate.autoRenew === true && authenticate.previousRecharge.length > 0) ? Math.ceil(authenticate.previousRecharge[0].total) * authenticate.noOfMonths : Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths,
                    "noOfMonths": authenticate.noOfMonths,
                    "channels": authenticate.selectedChannels.map(ele => {
                        return ele.channelId;
                    })
                }
                const url = `${authenticate.apiUrl}/api/recharge/`;
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                fetch(url, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(formData)
                }).then(response => {
                    if (response.status === 200) {
                        alert("Recharge request sent");
                        authenticate.setSelectedChannels([]);
                        authenticate.setActiveBasicBouquet("");
                        authenticate.setAutoRenew(false);
                        authenticate.setNoOfMonths(1);
                        authenticate.setPreviousRecharge([]);
                        authenticate.setRechargeTotal(0);
                        setSelectedSubscriber(false);
                        document.getElementById("name").value = "";
                        setPayment("select");
                        document.getElementsByName("basic bouquets").forEach(ele => {
                            ele.checked = false;
                        });
                        document.querySelectorAll("input[type='checkbox']").forEach(ele => {
                            ele.checked = false;
                        });
                        document.getElementById("totalBlock").style.display = "";
                        document.getElementById("categories").style.display = "block";
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    } else {
                        alert("Some error occured.");
                    }
                });
            }
        }
    }
    return (
        <>
            {authenticate.isLogin && <Box sx={{mb:"1rem"}}>
                <Container id="rechargeForm">
                    <Subscriber setSelectedSubscriber={setSelectedSubscriber} />
                    <TextField label='No of Months' id='noOfMonths' type='number' value={authenticate.noOfMonths} onChange={handleMonthValue} size='small' sx={{ width: "100%" }}/>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="paymentLabel">Payment</InputLabel>
                        <Select
                            labelId="paymentLabel"
                            id="payment"
                            value={payment}
                            label="Payment"
                            size='small'
                            onChange={handlePaymentChanged}
                        >
                            <MenuItem value="select">Select</MenuItem>
                            {authenticate.paymentMethods.map(ele => {
                                return <MenuItem key={ele.pk} value={ele.fields.method}>{ele.fields.method}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox onChange={handleAutoRenewChanged} value={authenticate.autoRenew} checked={authenticate.autoRenew} />} label="Auto Renew" />
                </Container>
                <Box id="categories">
                    {Object.keys(categoryDict).map((ele, ind) => {
                        return <React.Fragment key={ind}>
                            <Box className="categoryNames">{ele.toUpperCase()}</Box>
                            <Box className="category">
                                <Channel category={ele} categoryDict={categoryDict} />
                            </Box>
                        </React.Fragment>
                    })}
                </Box>
                <Container sx={{textAlign:"center", mt:"0.5rem"}}>
                    <Button variant="contained" onClick={handleRecharge}>Submit</Button>
                </Container>
            </Box>}
        </>
    )
}

export default Recharge
