import React, { useEffect, useContext, useState } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/recharge.css';
import { useNavigate } from 'react-router-dom';
import Channel from './Channel';
import { Box, Button, Container, TextField } from '@mui/material';

const PublicRecharge = () => {
    const authenticate = useContext(CableContext);
    const [categoryDict, setCategoryDict] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        authenticate.setAutoRenew(false);
        if (authenticate.selectedSubscriberData !== false) {
            const categiryUrl = `${authenticate.apiUrl}/api/category/`;
            fetch(categiryUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                if (data !== undefined) {
                    let parsedData = JSON.parse(data);
                    let categoryDict = {};
                    parsedData.forEach(ele => {
                        categoryDict[ele.pk] = [];
                    });
                    authenticate.setCategories(parsedData);
                    const channelUrl = `${authenticate.apiUrl}/api/channels/`;
                    fetch(channelUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json'}
                    }).then(responsech => {
                        if (responsech.status === 200) {
                            return responsech.json();
                        }
                    }).then(datach => {
                        if (datach !== undefined) {
                            datach.forEach(ele => {
                                if (ele.category !== null) {
                                    categoryDict[ele.category].push(ele);
                                }
                            });
                            setCategoryDict(categoryDict);
                            authenticate.setChannels(datach);
                        }
                    });
                }
            });
        } else {
            navigate("/subscriber");
        }
    }, []);
    const handleMonthValue = (e) => {
        authenticate.setNoOfMonths(e.target.value);
    }
    const handleRecharge = () => {
        if (authenticate.noOfMonths <= 0) {
            alert("No Of month cannot be zero or less");
        } else if (authenticate.selectedChannels.length === 0) {
            alert("Please Select Channels To Recharge");
        } else if (!(new Date() < new Date(authenticate.selectedSubscriberData.previous_recharge.toDate)) && authenticate.activeBasicBouquet === "") {
            alert("Please Select at least one basic bouquet To Recharge");
        } else {
            // authenticate.setSelectedChannels([]);
            authenticate.setActiveBasicBouquet("");
            // authenticate.setNoOfMonths(1);
            // authenticate.setRechargeTotal(0);
            document.getElementsByName("basic bouquets").forEach(ele => {
                ele.checked = false;
            });
            document.querySelectorAll("input[type='checkbox']").forEach(ele => {
                ele.checked = false;
            });
            document.getElementById("totalBlock").style.display = "";
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            navigate("/checkout");
        }
    }
    return (
        <>
            <Box sx={{ mb: "1rem" }}>
                <Container id="rechargeForm">
                    <TextField label='No of Months' id='noOfMonths' type='number' value={authenticate.noOfMonths} onChange={handleMonthValue} size='small' sx={{ width: "100%" }} />
                </Container>
                <Box id="categories">
                    {Object.keys(categoryDict).map((ele, ind) => {
                        return <React.Fragment key={ind}>
                            {!(new Date() < new Date(authenticate.selectedSubscriberData.previous_recharge.toDate) && ele === "basic bouquets") && <>
                                <Box className="categoryNames">{ele.toUpperCase()}</Box>
                                <Box className="category">
                                    <Channel category={ele} categoryDict={categoryDict} />
                                </Box>
                            </>}
                        </React.Fragment>
                    })}
                </Box>
                <Container sx={{ textAlign: "center", mt: "0.5rem" }}>
                    <Button variant="contained" onClick={handleRecharge}>Submit</Button>
                </Container>
            </Box>
        </>
    )
}

export default PublicRecharge
