import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RechargeDone = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);
    return (
        <Container sx={{textAlign:'center', fontWeight:'bold', pt:'1rem'}}>
            <h2>Recharge Done</h2>
            <div>Redirecting to home in 5 seconds</div>
        </Container>
    )
}

export default RechargeDone
