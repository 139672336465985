import React, { useContext } from 'react';
import CableContext from '../contexts/CableContext';
import '../css/checkout.css';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
    const authenticate = useContext(CableContext);
    const navigate = useNavigate();
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };
    const handleNotReadyToPay = () => {
        alert('Google Pay is not ready to pay.');
    }
    const paymentResponseToJsonString = (paymentResponse) => {
        var paymentResponseDictionary = {
            methodName: paymentResponse.methodName,
            details: paymentResponse.details,
            payerName: paymentResponse.payerName,
            payerPhone: paymentResponse.payerPhone,
            payerEmail: paymentResponse.payerEmail,
        };
        return JSON.stringify(paymentResponseDictionary, undefined, 2);
    }
    const completePayment = (instrument, result, msg) => {
        instrument.complete(result).then(() => {
            console.log('Payment succeeds.');
            console.log(msg);
            authenticate.setSelectedChannels([]);
            authenticate.setActiveBasicBouquet("");
            authenticate.setAutoRenew(false);
            authenticate.setNoOfMonths(1);
            authenticate.setPreviousRecharge([]);
            authenticate.setRechargeTotal(0);
            authenticate.setSelectedSubscriberData(false);
            navigate("/rechargeDone");
        }).catch((err) => {
            alert("Payment Failed");
            console.log(err);
        });
    }
    const processResponse = (instrument) => {
        var instrumentString = paymentResponseToJsonString(instrument);
        let formData = {
            "stbNumber": authenticate.selectedSubscriberData.stbNo,
            "payment": "online",
            "autoRenew": authenticate.autoRenew,
            // "totalRs": authenticate.autoRenew ? authenticate.selectedSubscriberData.previous_recharge.total : Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths,
            "noOfMonths": authenticate.noOfMonths,
            "channels": authenticate.selectedChannels.map(ele => {
                return ele.channelId;
            }),
            "instumentString" : instrumentString
        }
        fetch(`${authenticate.apiUrl}/api/checkPaymentAndRecharge/`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(formData),
        }).then((paymentResult) => {
            if (paymentResult.ok) {
                return paymentResult.json();
            }
            console.log('Error sending instrument to server.');
        }).then((paymentResultJson) => {
            completePayment(instrument, paymentResultJson.status, paymentResultJson.message);
        }).catch((err) => {
            console.log('Unable to process payment. ' + err);
        });
    }
    const showPaymentUI = (request, canMakePayment) => {
        if (!canMakePayment) {
            handleNotReadyToPay();
            return;
        }
        // Set payment timeout.
        let paymentTimeout = window.setTimeout(() => {
            window.clearTimeout(paymentTimeout);
            request.abort().then(() => {
                console.log('Payment timed out after 20 minutes.');
            }).catch(() => {
                console.log('Unable to abort, user is in the process of paying.');
            });
        }, 20 * 60 * 1000); /* 20 minutes */

        request.show().then((instrument) => {
            window.clearTimeout(paymentTimeout);
            processResponse(instrument); // Handle response from browser.
        }).catch((err) => {
            console.log(err);

        });
    }
    const canMakePaymentCache = 'canMakePaymentCache';
    const checkCanMakePayment = (request) => {
        // Check canMakePayment cache, use cache result directly if it exists.
        if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
            return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
        }

        // If canMakePayment() isn't available, default to assume the method is
        // supported.
        var canMakePaymentPromise = Promise.resolve(true);

        // Feature detect canMakePayment().
        if (request.canMakePayment) {
            canMakePaymentPromise = request.canMakePayment();
        }

        return canMakePaymentPromise.then((result) => {
            // Store the result in cache for future usage.
            sessionStorage[canMakePaymentCache] = result;
            return result;
        }).catch((err) => {
            console.log('Error calling canMakePayment: ' + err);
        });
    }
    const handleTransaction = () => {
        // navigate('/rechargeDone');
        /** Launches payment request flow when user taps on buy button. */
        if (!window.PaymentRequest) {
            console.log('Web payments are not supported in this browser.');
            return;
        }

        // Create supported payment method.
        const supportedInstruments = [
            {
                supportedMethods: ['https://tez.google.com/pay'],
                data: {
                    pa: 'darekardevidas@ybl',
                    pn: 'Yashdeep Digital Cable Network',
                    tr: uniqueId(),
                    url: `${authenticate.apiUrl}/checkout`,
                    mc: '4814',
                    tn: 'Cable Tv Recharge',
                },
            }
        ];

        // Create order detail data.
        const details = {
            total: {
                label: 'Total',
                amount: {
                    currency: 'INR',
                    value: `${authenticate.selectedSubscriberData.pending > 0 ? authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total + authenticate.selectedSubscriberData.pending) : Math.ceil((Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths) + authenticate.selectedSubscriberData.pending) : authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total) : Math.ceil(Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths)}.00`,
                },
            },
            displayItems: [{
                label: 'Original Amount',
                amount: {
                    currency: 'INR',
                    value: `${authenticate.selectedSubscriberData.pending > 0 ? authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total + authenticate.selectedSubscriberData.pending) : Math.ceil((Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths) + authenticate.selectedSubscriberData.pending) : authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total) : Math.ceil(Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths)}.00`,
                },
            }],
        };

        // Create payment request object.
        let request = null;
        try {
            request = new PaymentRequest(supportedInstruments, details);
        } catch (e) {
            console.log('Payment Request Error: ' + e.message);
            return;
        }
        if (!request) {
            console.log('Web payments are not supported in this browser.');
            return;
        }

        var canMakePaymentPromise = checkCanMakePayment(request);
        canMakePaymentPromise.then((result) => {
            showPaymentUI(request, result);
        }).catch((err) => {
            console.log('Error calling checkCanMakePayment: ' + err);
        });
    }
    return (
        <div>
            <Container sx={{ textAlign: "center", mt: "1rem", mb: "1rem" }}>
                <div id='checkoutGrid'>
                    <div>Recharge Total</div>
                    <div>{authenticate.autoRenew ? authenticate.selectedSubscriberData.previous_recharge.total : Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths}</div>
                    {authenticate.selectedSubscriberData.pending > 0 && <><div>Pending Rs</div>
                        <div>{authenticate.selectedSubscriberData.pending}</div>
                    </>}
                    <div className='borderTop'>Grand Total</div>
                    <div className='borderTop'>{authenticate.selectedSubscriberData.pending > 0 ? authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total + authenticate.selectedSubscriberData.pending) : Math.ceil((Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths) + authenticate.selectedSubscriberData.pending) : authenticate.autoRenew ? Math.ceil(authenticate.selectedSubscriberData.previous_recharge.total) : Math.ceil(Math.ceil(authenticate.rechargeTotal) * authenticate.noOfMonths)}</div>
                </div>
                <Button variant='contained' onClick={handleTransaction} sx={{ mt: "1rem" }}>Pay Using Gpay</Button>
            </Container>
        </div>
    )
}

export default Checkout
