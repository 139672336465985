import './App.css';
import Navbar from './components/Navbar';
import CableState from './contexts/CableState';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import cableLogo from './images/yashdeepLogoBlue.webp';
import Home from './components/Home';
import Recharge from './components/Recharge';
import Payment from './components/Payment';
import Refresh from './components/Refresh';
import Status from './components/Status';
import Login from './components/Login';
import SelectedSubscriber from './components/SelectedSubscriber';
import PageNotFound from './components/PageNotFound';
import Report from './components/Report';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PublicRecharge from './components/PublicRecharge';
import Checkout from './components/Checkout';
import RechargeDone from './components/RechargeDone';
import CanceledPayment from './components/CanceledPayment';
import Support from './components/Support';
import PreviousRecharges from './components/PreviousRecharges';

function App() {
  return (
    <>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CableState>
            <Navbar logo={cableLogo} />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<Login />} />
              <Route path='/recharge' element={<Recharge />} />
              <Route path='/payment' element={<Payment />} />
              <Route path='/refresh' element={<Refresh />} />
              <Route path='/status' element={<Status />} />
              <Route path='/subscriber' element={<SelectedSubscriber />} />
              <Route path='/report' element={<Report />} />
              <Route path='/selectAndAddChannels' element={<PublicRecharge />} />
              <Route path='/checkout' element={<Checkout />} />
              <Route path='/rechargeDone' element={<RechargeDone />} />
              <Route path='/cancelled' element={<CanceledPayment />} />
              <Route path='/support' element={<Support />} />
              <Route path='/previousRecharge' element={<PreviousRecharges />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </CableState>
        </LocalizationProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
