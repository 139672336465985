import CableContext from "./CableContext"
import { useState } from "react"

const CableState = (props) => {
    const apiUrl = "https://yashdeepcable.com";
    const [isLogin, setIsLogin] = useState(false);
    const [categories, setCategories] = useState([]);
    const [channels, setChannels] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [subscribers, setSubscribers] = useState([]);
    const [rechargeTotal, setRechargeTotal] = useState(0);
    const [previousRecharge, setPreviousRecharge] = useState([]);
    const [noOfMonths, setNoOfMonths] = useState(1);
    const [autoRenew, setAutoRenew] = useState(false);
    const [activeBasicBouquet, setActiveBasicBouquet] = useState("");
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [selectedSubscriberData, setSelectedSubscriberData] = useState(false);
    const handleAuthenticate = (value) => {
        setIsLogin(value);
    }
    return (
        <CableContext.Provider value={{apiUrl, isLogin, handleAuthenticate, categories, setCategories, channels, setChannels, paymentMethods, setPaymentMethods, subscribers, setSubscribers, rechargeTotal, setRechargeTotal, previousRecharge, setPreviousRecharge, noOfMonths, setNoOfMonths, autoRenew, setAutoRenew, activeBasicBouquet, setActiveBasicBouquet, selectedChannels, setSelectedChannels, selectedSubscriberData, setSelectedSubscriberData}}>
            {props.children}
        </CableContext.Provider>
    )
}

export default CableState
